
import React from 'react'
import { MDXTag } from '@mdx-js/tag'
import { CiteCluster } from './Cite';
import { Diff } from './Diff';
import { DocBlock } from './Meta';
import { Tag } from './Tags';
import { Rule, OneTest } from './Results';
import { DemoRule } from './Readme-data';

export default class MDXContent extends React.Component {
  constructor(props) {
    super(props)
    this.layout = null
  }
  render() {
    const { components, ...props } = this.props

    return <MDXTag
             name="wrapper"
             
             components={components}>{/* vim: set fo-=tc : */}

<MDXTag name="h1" components={components} props={{"id":"note-work-in-progress"}}>{`Note: Work In Progress`}</MDXTag>
<MDXTag name="p" components={components}>{`This is extremely not finished. The README here is also out of date — there
are changes afoot regarding the availability of CSL-M-level features elsewhere,
so for now, a CSL-M style is not being actively developed.`}</MDXTag>
<MDXTag name="h1" components={components} props={{"id":"how-to-use-this-guide"}}>{`How to use this guide`}</MDXTag>
<MDXTag name="p" components={components}>{`Citing legal materials is complicated, but the days of typing out footnotes
with fiddly italics, manually managing ibid and footnote numbers and messing up
by missing a comma are fading fast. This project includes implementations of
AGLC4 in `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://citationstyles.org/"}}>{`CSL`}</MDXTag>{` and `}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://citeproc-js.readthedocs.io/en/latest/csl-m/index.html"}}>{`CSL-M`}</MDXTag>{`, to be used with Zotero/Mendeley/many others or
Juris-M respectively. This documentation aims to cover all the different ways
you can use the styles to automatically produce compliant AGLC4 citations.`}</MDXTag>
<MDXTag name="p" components={components}>{`This documentation is generated from a `}<MDXTag name="em" components={components} parentName="p">{`test suite`}</MDXTag>{`. Each test in the suite is
associated with a particular rule with which it is attempting to check
compliance. It should be presumed that an AGLC rule not found here should be
entered manually in a footnote. Due to the complexity of the AGLC, it will
sometimes be necessary to use the tools in an unintuitive or non-standard way
— these cases are documented.`}</MDXTag>
<MDXTag name="p" components={components}>{`Each of the two documentation sets can be navigated using the same table of
contents as the AGLC4 book itself. Many of the test cases are lifted from the
book. Each rule can be linked to by clicking the anchor next to the rule title
and copying the resulting URL.`}</MDXTag>
<MDXTag name="h2" components={components} props={{"id":"csl-csl-m-zotero-juris-m"}}>{`CSL? CSL-M? Zotero? Juris-M?`}</MDXTag>
<MDXTag name="p" components={components}>{`This project works with two closely related but different kinds of citation
processor. `}<MDXTag name="strong" components={components} parentName="p">{`You can pick which documentation set of CSL or CSL-M to browse at
the top of the page.`}</MDXTag>{` They are:`}</MDXTag>
<MDXTag name="ul" components={components}>
<MDXTag name="li" components={components} parentName="ul">{`CSL: Citation Style Language. It is very widely supported, but lacks a host
of features and fields necessary to comply with many of the rules.`}</MDXTag>
<MDXTag name="li" components={components} parentName="ul">{`CSL-M: A variant of CSL built with the sprawling complexity of legal
citations in mind. It supports adapting to different jurisdictions and
languages, along with many law-specific features and fields. It is less
widely supported: it can only be used with the Juris-M reference manager. At
present, the only way to use it in a document is with the Juris-M Microsoft
Word plugin, however `}<MDXTag name="a" components={components} parentName="li" props={{"href":"https://github.com/cormacrelf/citeproc-rs"}}>{`a project`}</MDXTag>{`
is under way to rewrite the processor such that CSL-M can be used with Pandoc
as well. CSL-M was developed by Frank Bennett of Nagoya University, largely
to cover the Bluebook citation system.`}</MDXTag>
</MDXTag>
<MDXTag name="p" components={components}>{`Because of the different feature sets, large swathes of the AGLC can only be
covered by the CSL-M variant. If you are a current, happy CSL user (i.e. you
only cite Australian reported judgments, legislation, journal articles and
books), you can proceed as usual. If you find yourself citing parliamentary
committee hearings, foreign domestic or international materials like treaties,
reports with institutional authors, the less common unreported case modes, or
you’re generally hitting the edge cases in the AGLC, you may wish to switch. A
project for a later date is to add a comparison chart based on the test suites.`}</MDXTag>
<MDXTag name="h2" components={components} props={{"id":"installing"}}>{`Installing`}</MDXTag>
<MDXTag name="p" components={components}>{`These styles can’t really be installed through the standard channels yet. When
they can be, it will be documented here.`}</MDXTag>
<MDXTag name="h2" components={components} props={{"id":"rules"}}>{`Rules`}</MDXTag>
<MDXTag name="p" components={components}>{`Following is an example rule. You will notice it comprises two test cases, one
of which has failed, because the result was not identical to the expected
output. Rules might include documentation that explains how to get the style to
do what you want, or warnings about what you must or mustn’t do for correct
output.`}</MDXTag>
<DemoRule />
<MDXTag name="p" components={components}>{`…`}</MDXTag>
<MDXTag name="table" components={components}>
<MDXTag name="thead" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="thead">
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Tag`}</MDXTag>
<MDXTag name="th" components={components} parentName="tr" props={{"align":null}}>{`Meaning`}</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="tbody" components={components} parentName="table">
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><Tag type="passed" /></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`The style produces the correct output, down to italics and curly quotation marks.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><Tag type="failed" /></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`The style gets it wrong. A diff will be shown. Be careful using the style in this way.`}</MDXTag>
</MDXTag>
<MDXTag name="tr" components={components} parentName="tbody">
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}><Tag type="stub" /></MDXTag>
<MDXTag name="td" components={components} parentName="tr" props={{"align":null}}>{`There was an empty test defined.`}</MDXTag>
</MDXTag>
</MDXTag>
</MDXTag>
<MDXTag name="h2" components={components} props={{"id":"cite-clusters"}}>{`Cite clusters`}</MDXTag>
<p>
A cite cluster looks like <CiteCluster cites={[{ id: "citekey", label: 'locator label', locator: "locator", prefix: 'prefix', suffix: 'suffix' }]}/>.
</p>
<MDXTag name="p" components={components}>{`You enter these with the Microsoft Word plugin and its cite entry UI, or in
Pandoc using the `}<MDXTag name="inlineCode" components={components} parentName="p">{`[prefix @citekey label locator suffix]`}</MDXTag>{` syntax (see the
`}<MDXTag name="a" components={components} parentName="p" props={{"href":"https://pandoc.org/MANUAL.html#citations"}}>{`manual`}</MDXTag>{` for more).`}</MDXTag>
<MDXTag name="p" components={components}>{`A semicolon in the cluster means there are multiple cites:`}</MDXTag>
<p>
<CiteCluster cites={[{ id: "citekey", prefix: 'prefix' }, { id: "key2", prefix: "second prefix" }]}/>.
</p>
<MDXTag name="h2" components={components} props={{"id":"acknowledgements"}}>{`Acknowledgements`}</MDXTag>
<MDXTag name="p" components={components}>{`Thanks to the University of Technology Sydney for supporting the development of the CSL-M style.`}</MDXTag>
           </MDXTag>
  }
}
export const tableOfContents = (components={}) => [
  {
    id: "csl-csl-m-zotero-juris-m",
    level: 2,
    title: "CSL? CSL-M? Zotero? Juris-M?",
    children: [
        
      ]
  },
{
    id: "installing",
    level: 2,
    title: "Installing",
    children: [
        
      ]
  },
{
    id: "rules",
    level: 2,
    title: "Rules",
    children: [
        
      ]
  },
{
    id: "cite-clusters",
    level: 2,
    title: "Cite clusters",
    children: [
        
      ]
  },
{
    id: "acknowledgements",
    level: 2,
    title: "Acknowledgements",
    children: [
        
      ]
  }
]

export const frontMatter = {}

